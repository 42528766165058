import React from "react"

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div>©adblind.pl All rights reserved.</div>
      </div>
    </div>
  )
}

export { Footer }
