const translations = {
  navbar: {
    about: "o nas",
    news: "aktualności",
    collections: "kolekcje",
    contact: "kontakt",
    work: "praca",
    inspirations: "inspiracje",
    filters: "filtry",
  },
  hero: {
    quotes: [
      {
        quote: '"Piękno jest jednocześnie kształtem czegoś i czegoś zasłoną"',
        author: "- Cyprian Kamil Norwid",
      },
      {
        quote: '"Sztuka to piękno, a piękno to coś, co porusza nasze serca"',
        author: "- Arystoteles",
      },
      {
        quote: '"Prawdziwe piękno tkwi w szczegółach"',
        author: "- David Hume",
      },
    ],
  },
  tips: {
    colors: "Możliwość dostosowania kolorystyki tkanin",
    quality: "Doskonała jakość",
    settings: "Realizujemy zamówienia na nietypowe tkaniny",
  },
  aboutUs: {
    title: "Kim jesteśmy?",
    description1: `Od 2011 roku zajmujemy się tworzeniem kolekcji tkanin dekoracyjnych do systemów osłonowych.
    Obecnie posiadamy w naszej ofercie tkaniny dzień i noc, zaciemniające termo „silver”, a także
    nowość dzień i noc fala. Prowadzimy dystrybucję tkanin na terenie Polski oraz Europy. Naszą domeną
    jest zapewnianie najwyższej jakości oferowanych produktów, co przekłada się na długoterminową
    współpracę z producentami rolet. Przeprowadzamy nieustającą kontrolę jakości oferowanych tkanin.
    Nasza działalność obecnie skupia się na poszerzaniu oferty tkanin wyselekcjonowanych pod
    względem jakościowym, dostosowanych do potrzeb naszych klientów.`,
    description2: `Od 2020 roku podążając za potrzebami rynku wprowadziliśmy również do naszej oferty filtry
    powietrza. Jest to dodatkowa działalność naszej firmy w której prężnie się rozwijamy.`,
  },

  services: {
    title: "ADblind w liczbach",
    curtains: {
      title: "sprzedanych tkanin",
    },
    blinds: {
      title: "produktów w ofercie",
    },
    something: {
      title: "lat na rynku",
    },
    over: "ponad",
  },

  collections: {
    title: "Nasze kolekcje",
    button: "Kontakt",
    menu: {
      dayAndNight: "Dzień i noc",
      dayAndNightWave: "Dzień i noc fala",
      blackout: "Zaciemniające termosilver",
    },
    dayAndNight: {
      description:
        "Kolekcje składają się z tkanin jednobarwnych oraz wielobarwnych w bardzo szerokiej kolorystyce. Są to tkaniny naturalne, strukturalne oraz satynowe.",
      description2: "Sprawdź nasze wzorniki:",
      naturalCollectionLinkText: "NATURAL",
      woodCollectionLinkText: "WOOD",
      description3:
        "Pełna kolorystyka tkanin możliwa do przesłania po kontakcie.",
      contactDescription:
        "Jeśli chcą Państwo poznać naszą Kolekcję Tkanin Dzień i Noc zapraszamy do kontaktu.",
    },
    dayAndNightWave: {
      description:
        "Prezentujemy Państwu nowy model tkanin dzień i noc o wzorze sinusoidalnej fali.",
      description1: `Kolekcja dzień i noc fala dostępna jest w kilku wersjach kolorystycznych, od białej, przez różne szarości, po czarny.`,
      description2: `Co istotne w produkcji rolet, tkaniny te działają na tych samych mechanizmach co zwykłe
      tkaniny dzień i noc, nie wymagają dedykowanych systemów więc wdrożenie tkanin do oferty
      producenta jest bardzo sprawne. Tkaniny są cieńsze od podstawowej naszej serii dzień i noc
      Natural (gramatura poniżej 100g/m2) co powoduje że tkanina mieści się swobodnie w
      dowolnej kasecie.`,
      contactDescription:
        "Jeśli chcą Państwo poznać naszą Kolekcję Tkanin Dzień i Noc Fala zapraszamy do kontaktu.",
    },
    balckout: {
      description:
        "Kolekcja tkanin zaciemniających to materiały cechujące się najwyższą europejską jakością oraz wzornictwem opartym na najnowszych światowych trendach.",
      header: "Tkanina składa się z 3 warstw:",
      point1: `1. Warstwa zewnętrzna pokryta srebrną powłoką, ma działanie termoizolacyjne, odbija
        promienie słoneczne latem a zimą utrzymuje ciepło wewnątrz pomieszczeń.`,
      point2: `2. Warstwa gumy zapewniająca w 100% zaciemnienie pomieszczenia.`,
      point3: `3. Warstwa wewnętrzna pokryta tkaniną dekoracyjną wybieraną pod klienta. Dostępna
        kolorystyka od białego, poprzez krem, szarości, po czarny.`,
      specification: "Specyfikacja tkaniny:",
      spec1: `Szerokość na belce – 200 cm`,
      spec2: `Nawój – 40mb`,
      spec3: `Gramatura – 220g/m2`,
      contactDescription:
        "Jeśli chcą Państwo poznać naszą kolekcję tkanin zaciemniających zapraszamy do kontaktu.",
    },
  },
  inspirations: {
    title: "Nasze inspiracje",
  },
  filters: {
    title: "Filtry powietrza",
    description1:
      "Obecnie specjalizujemy się w sprzedaży filtrów klasy F7 i F9 oraz filtrów Hepa H11-H14.",
    button: "Klasyfikacja możliwości",
  },
  news: {
    title: "Aktualności",
  },
  contact: {
    title: "Kontakt",
    name: "Imię",
    phone: "Numer kontaktowy",
    facebook: "Facebook",
    email: "Email",
    subject: "Temat",
    message: "Treść",
    send: "Wyślij",
    required: "To pole jest wymagane",
    tooShort: "Treść tego pola jest za krótka",
    wrongEmail: "Niepoprawny format email",
    tooLong: "Treść tego pola jest za długa",
    emailSentSuccess: "Wiadomość została wysłana",
    emailFail: "Coś poszło nie tak. Spróbuj ponownie później.",
    close: "Zamknij",
  },
}

export default translations
