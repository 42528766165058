const translations = {
  navbar: {
    about: "about us",
    news: "news",
    collections: "collections",
    contact: "contact",
    inspirations: "inspirations",
    filters: "filters",
  },
  hero: {
    quotes: [
      {
        quote: '"Everything has beauty, but not everyone sees it."',
        author: "- Confucius",
      },
      {
        quote: '"Think of all the beauty still left around you and be happy."',
        author: "- Anne Frank ",
      },
      {
        quote: '"A thing of beauty is a joy forever."',
        author: "- John Keats",
      },
    ],
  },
  tips: {
    colors: "Posibillity to adjust coloring of the fabric",
    quality: "Excellent quality",
    settings: "Fullfill non-typical fabric orders",
  },
  aboutUs: {
    title: "Who we are?",
    description1:
      "Since 2011 we are focusing on creating full range of decorative fabrics for shielding systems. Currently we offer day and night fabrics, shaded blackout and a brand new system day and night wave. We run whole range distribution of our fabrics in Poland and abroad. Our main aim is to provide best quality of service and materials what leads to long cooperation with producents of blinds. We as a company, constantly carry out all quality controls of suggested fabrics. At the moment our company is focusing on choosing carefully selected quality fabrics, customized to our clients needs.",
    description2:
      "From year 2020 by following brand new trends and needs we added to our offer air filters. It is another activity loop which we are currently dynamically develop.",
  },

  services: {
    title: "adblind numbers",
    something: {
      title: "selled fabrics",
    },
    curtains: {
      title: "offered manufactures",
    },
    blinds: {
      title: "years on market",
    },
    over: "over",
  },

  collections: {
    title: "Our collections",
    menu: {
      dayAndNight: "Day and night",
      dayAndNightWave: "Day and night wave",
      blackout: "Blackout thermo silver",
    },
    dayAndNight: {
      description:
        "The collections consist of single-color and multi-colored fabrics in a very wide range of colors. These are natural, structural and satin fabrics.",
      description2: "Check out our pattern books:",
      naturalCollectionLinkText: "NATURAL",
      woodCollectionLinkText: "WOOD",
      description3: "Wider range of fabric colors can be sent after contact.",
      contactDescription:
        "If you want to know some details about our Day and Night Fabric Collection, please contact us.",
    },
    dayAndNightWave: {
      description: `We present you a new model of day and night fabrics with a sinusoidal wave pattern.`,
      description1: `The collection consists of single-color fabrics with a wavy structure. Compared to a standard fabric day and night, it offers additional options for darkening between day and night.`,
      description2: `Whats more important, in the production of roller blinds, these fabrics operate on the same mechanisms as regular ones day and night fabrics do not require dedicated systems, so the implementation of fabrics into the offer the manufacturer is very smooth. The fabrics are thinner than our basic day & night range. Natural (basis weight below 100g / m2), which allows the fabric to fit freely in any cassette.`,
      contactDescription:
        "If you want to know some details about our Day and Night Wave Fabric Collection, please contact us.",
    },
    balckout: {
      description:
        "The collection of blackout fabrics are materials characterized by the highest European quality and designs based on the latest global trends.",
      header: "Fabric consists of 3 layers:",
      point1: `1. The outer layer is covered with a silver coating. Has a thermal osolation effect. reflects
      the sun's rays in summer and in winter keeps the heat inside.`,
      point2: `2. Rubbered layer ensuring 100% darkening of the room.`,
      point3: `3. The inner layer is covered with a decorative fabric selected for the customer. Available colors from white, through cream, gray, to black..`,
      specification: "Fabric specification:",
      spec1: `Width on the beam – 200 cm`,
      spec2: `Warp – 40mb`,
      spec3: `Weight – 220g/m2`,
      contactDescription:
        "If you want to know some details about our Blackout Fabric Collection, please contact us.",
    },
    button: "Contact",
  },
  inspirations: {
    title: "Our inspirations",
  },

  filters: {
    title: "Air filters",
    description1:
      "We also specialize in sale of filters class F7 and F9 as wall as H11-H14 Hepa filters",
    button: "Classification potential",
  },

  news: {
    title: "News",
  },
  contact: {
    title: "Contact us",
    name: "Name",
    phone: "Phone",
    facebook: "Facebook",
    email: "Email",
    subject: "Subject",
    message: "Treść",
    send: "Send",
    required: "This field is required",
    tooShort: "This field is too short",
    wrongEmail: "Wrong email format",
    tooLong: "This field is too long",
    emailSentSuccess: "Email has been sent",
    emailFail: "Something went wrong. Try again later.",
    close: "Close",
  },
}

export default translations
